
/* eslint-disable @typescript-eslint/no-explicit-any */

import { defineComponent, onMounted, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { EventEduActions } from "@/store/vm/event-edu/enums";
import { StepResult } from "@/store/captain/CaptainModule";
import CropperComponent from "@/../src/components/upload/cropper.vue";
import router from "@/router";
import { useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import TinyMCE from "@/components/editor/tinyMCE.vue";

export default defineComponent({
  name: "upsert-eventEdu",
  components: {
    tiny: TinyMCE,
    cropper: CropperComponent,
  },
  props: {
    eventEduId: {
      type: [Number, Boolean],
      required: true,
      default: false,
    },
  },
  emits: ["reset"],
  methods: {
    activateCropper: function () {
      (this.$refs["coverCropper"] as any).selectFile();
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onUploadCoverFail: function (response) {
      Swal.fire({
        position: "top-end",
        text: "Đã xảy ra lỗi upload ảnh, vui lòng thử lại sau!",
        icon: "error",
        buttonsStyling: false,
        timer: 2000,
        width: 400,
        showCancelButton: false,
        showConfirmButton: false,
      });
    },
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    // Get params from url
    const eventEduId =
      route.params["eventEduId"] === "create"
        ? false
        : route.params["eventEduId"];

    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const content = ref<string>("");

    const defaultData = {
      event_id: "",
      cover: "",
      name: "",
      content: "",
      location: "",
      ref_url: "",

      start_time: "",
      end_time: "",

      type: 0,
      open_before: 15,
      meeting: "",
      status: "",
      point: 10,
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Sự kiện", ["Sự kiện"]);
      MenuComponent.reinitialization();

      // get detail
      if (eventEduId) {
        const detail = await store
          .dispatch(EventEduActions.GET_DETAIL, eventEduId)
          .catch((e) => {
            console.log(e);
          });

        formData.value = detail;
        content.value = formData.value.content;

        dateTimeRangePicker.value = [
          new Date(detail.start_time),
          new Date(detail.end_time),
        ];
      }
    });
    const formData = ref({ ...defaultData });

    const rules = ref({
      name: [
        {
          required: true,
          message: "Yêu cầu tên sự kiện",
          trigger: "change",
        },
      ],
    });

    const isCreate = !eventEduId;

    const actionCreate = (par) => {
      return store.dispatch(EventEduActions.CREATE, par).catch((e) => {
        console.log(e);
      });
    };

    const actionUpdate = async (par) => {
      return await store.dispatch(EventEduActions.UPDATE, par).catch((e) => {
        console.log(e);
      });
    };

    const submit = async () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;
          let stepResult: StepResult;
          if (isCreate) {
            stepResult = await actionCreate(formData.value);
          } else {
            stepResult = await actionUpdate(formData.value);
          }

          loading.value = false;
          if (stepResult.isSuccess) {
            Swal.fire({
              position: "top-end",
              text: "Success!",
              icon: "success",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            }).then(() => {
              router.push({ name: "EventEdu" });
            });
          } else {
            Swal.fire({
              position: "top-end",
              text: "Đã xảy ra lỗi, vui lòng thử lại sau!",
              icon: "error",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
          }
        } else {
          Swal.fire({
            text: "Định dạng dữ liệu không đúng, vui lòng kiểm tra lại biểu mẫu",
            icon: "error",
            buttonsStyling: false,
            timer: 2000,
            width: 400,
            showCancelButton: false,
            showConfirmButton: false,
          });
          return false;
        }
      });
    };

    const onUploadCoverSuccess = (imageUrl) => {
      formData.value.cover = imageUrl;
    };

    const onChangeContent = (newValue) => {
      formData.value.content = newValue;
    };

    const onChangeDateTimePicker = (newValue) => {
      formData.value.start_time = new Date(newValue[0]).toISOString();
      formData.value.end_time = new Date(newValue[1]).toISOString();
    };

    const dateTimeRangePicker = ref<Date[]>([]);

    const defaultTimeRange = [new Date().setHours(8), new Date().setHours(10)];

    const labelType = [
      {
        value: 0,
        label: "Sự kiện online",
      },
      {
        value: 1,
        label: "Sự kiện offline",
      },
      {
        value: 2,
        label: "Sự kiện Cross online + offline",
      },
    ];

    const labelStatus = [
      {
        value: 0,
        label: "Không công khai (chưa hiển thị trên App)",
      },
      {
        value: 1,
        label: "Công khai (hiển thị trên App)",
      },
      {
        value: 2,
        label: "Ẩn - sau khi sự kiện kết thúc, không hiển thị trên App",
      },
    ];

    return {
      isCreate,
      onUploadCoverSuccess,
      defaultData,
      formData,
      submit,
      formRef,
      rules,
      loading,
      content,
      onChangeContent,
      dateTimeRangePicker,
      defaultTimeRange,
      labelType,
      labelStatus,
      onChangeDateTimePicker,
    };
  },
});
